import {
  Box,
  Center,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdWaterDrop } from 'react-icons/md';

import {
  WorkOrderSliderTextColor,
  WorkOrderSliderThumbStyle,
  WorkOrderSliderTrackStyle,
} from './WorkOrderSlider.styles.tsx';

export const WorkOrderSlider = () => {
  const [sliderValue, setSliderValue] = useState(66);

  return (
    <Box pb={6} width="100%" mx="auto">
      <Slider
        aria-label="slider-ex-1"
        defaultValue={0}
        min={0}
        max={100}
        step={33}
        value={sliderValue}
        onChange={(val) => setSliderValue(val)}
      >
        <SliderMark value={0} mt="6" fontSize="sm">
          <Text sx={WorkOrderSliderTextColor}>DAMAGED</Text>
        </SliderMark>
        <SliderMark value={33} mt="6" ml="-20px" fontSize="sm">
          <Text sx={WorkOrderSliderTextColor}>POOR</Text>
        </SliderMark>
        <SliderMark value={66} mt="6" ml="-33px" fontSize="sm">
          <Text sx={WorkOrderSliderTextColor}>AVERAGE</Text>
        </SliderMark>
        <SliderMark value={100} mt="6" ml="-40px" fontSize="sm">
          <Text sx={WorkOrderSliderTextColor}>GOOD</Text>
        </SliderMark>
        <SliderTrack sx={WorkOrderSliderTrackStyle}>
          <SliderFilledTrack bg="transparent" />
        </SliderTrack>
        <SliderThumb boxSize={10} sx={WorkOrderSliderThumbStyle}>
          <Center>
            <MdWaterDrop fontSize="25px" color="white" />
          </Center>
        </SliderThumb>
      </Slider>
    </Box>
  );
};
