import { Reorder, useDragControls } from 'framer-motion';

import { type WorkOrderType } from '../../fixtures/WorkOrders.tsx';
import { WorkOrderItem } from '../WorkOrderItem';

type ReorderWorkOrderItemProps = {
  item: WorkOrderType | undefined;
};

export const ReorderWorkOrderItem = ({ item }: ReorderWorkOrderItemProps) => {
  const controls = useDragControls();

  if (!item) {
    return <></>;
  }

  return (
    <Reorder.Item value={item.id} dragListener={false} dragControls={controls}>
      <WorkOrderItem data={item} onPointerDown={(e) => controls.start(e)} />
    </Reorder.Item>
  );
};
