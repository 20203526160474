import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { type Settings } from 'settings';

export default function (settings: Settings) {
  if (['production', 'staging'].includes(import.meta.env.MODE)) {
    Sentry.init({
      dsn: settings.SENTRY_DSN,
      environment: import.meta.env.MODE,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          /**
           * WORKAROUND CORS error:
           *
           * > Request header field baggage is not allowed by Access-Control-Allow-Headers in preflight response.
           *
           * > If you don't want to attach headers anywhere (i.e. turn off
           * > distributed tracing), you can just specify an empty array:
           * > tracePropagationTargets: [].
           * > https://github.com/getsentry/sentry-javascript/issues/6077#issuecomment-1322030967
           */
          tracePropagationTargets: [
            // /localhost:\d{4}/,
            // settings.BASE_API_ORIGIN + '/api',
          ],
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
