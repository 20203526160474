import { type SystemStyleObject } from '@chakra-ui/react';

export const DashboardButtonMapStyle: SystemStyleObject = {
  textTransform: '',
  fontFamily: 'arialBold',
  fontSize: '18px',
  color: 'white',
  backgroundColor: '#52c6e5',
  border: '2px solid white',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: '#288fce',
  },
};

export const DashboardHeadingStyle: SystemStyleObject = {
  fontFamily: 'arialReg',
  fontSize: '28px',
  color: '#0033a0',
  fontWeight: '600',
};

export const DashboardAccordionStyle: SystemStyleObject = {
  backgroundColor: 'transparent',
  border: 0,
};

export const DashboardCompletedAccordionStyle: SystemStyleObject = {
  backgroundColor: '#0033a0',
  border: 0,
};
