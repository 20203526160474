import { type SystemStyleObject } from '@chakra-ui/react';

const _brandBlue = 'var(--chakra-colors-brandBlue)';

export const WorkOrderCommentsHeadingStyle: SystemStyleObject = {
  fontFamily: 'arialReg',
  fontSize: '14px',
};

export const WorkOrderCommentsTextareaStyle: SystemStyleObject = {
  backgroundColor: 'white',
};

export const WorkOrderCommmentsSaveButtonStyle: SystemStyleObject = {
  color: 'white',
  minWidth: '92px',
  backgroundColor: '#0057ff',
  transition: '0.3s all linear',
  _hover: {
    backgroundColor: '#00379BFF',
  },
};

export const WorkOrderCommentsContainerStyle: SystemStyleObject = {
  marginTop: '10px',
  paddingBottom: '3px',
  borderBottom: '1px solid #CBD5E0',
};

export const WorkOrderCommentsAuthorStyle: SystemStyleObject = {
  fontSize: '12px',
};

export const WorkOrderCommentsTextStyle: SystemStyleObject = {};
