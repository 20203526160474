import { Avatar, Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RouterLink,
} from '@burnsred/ui-chakra';
import appLogo from 'assets/app-logo.png';
import profilePicture from 'assets/profilepic.png';
import { DigitalId } from 'components';

import {
  HeaderBoxStyle,
  HeaderFlexStyle,
  HeaderLogoStyle,
  UserFlexStyle,
  UserMenuButtonStyle,
  UserMenuItemStyle,
  UserMenuListStyle,
} from './Header.styles.tsx';

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box as="nav" sx={HeaderBoxStyle}>
      <Flex sx={HeaderFlexStyle}>
        <RouterLink to={'/dashboard'}>
          <img style={HeaderLogoStyle} alt="App Logo" src={appLogo} />
        </RouterLink>
        <Flex sx={UserFlexStyle}>
          <Text fontSize="sm" color="#0033a0" fontFamily="arialReg">
            Anthony Higginbottom
          </Text>
          <Avatar
            size="sm"
            name="Kola Tioluwani"
            src={profilePicture}
            border="1px solid #2b2d30"
          />{' '}
          <Menu placement="top-end">
            <MenuButton sx={UserMenuButtonStyle} />

            <MenuList sx={UserMenuListStyle}>
              <MenuItem as={RouterLink} to="/profile" sx={UserMenuItemStyle}>
                My profile
              </MenuItem>
              <MenuItem onClick={onOpen} sx={UserMenuItemStyle}>
                Digital ID
              </MenuItem>
              <MenuItem as={RouterLink} to="#" sx={UserMenuItemStyle}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <DigitalId isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
