import type { SystemStyleObject } from '@chakra-ui/react';

export const WorkOrderImageModalContentStyle: SystemStyleObject = {
  width: '100%',
  maxWidth: '100vw',
  height: '100vh',
  margin: 0,
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'black',
};

export const WorkOrderImageModalHeaderStyle: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: 'black',
  height: '64px',
  alignItems: 'center',
  paddingX: 4,
};

export const WorkOrderImageModalSubHeaderStyle: SystemStyleObject = {
  backgroundColor: 'black',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'white',
  gap: '10px',
  paddingBottom: '10px',
};

export const WorkOrderImageModalCloseButton: SystemStyleObject = {
  background: 'transparent',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'arialMed',
  fontSize: '16px',
  _hover: {
    backgroundColor: 'transparent',
  },
};
