import {
  Accordion,
  Button,
  Flex,
  Heading,
  ScaleFade,
  useDisclosure,
} from '@chakra-ui/react';
import { Reorder } from 'framer-motion';
import { useMemo, useState } from 'react';
import { MdMap } from 'react-icons/md';

import { useWorkOrders } from 'api/useWorkOrder.tsx';
import {
  CompletedWorkOrdersAccordion,
  GoogleMapModal,
  MessageBanner,
  ReorderWorkOrderItem,
  WorkOrderIcons,
} from 'components';

import {
  DashboardAccordionStyle,
  DashboardButtonMapStyle,
  DashboardHeadingStyle,
} from './Dashboard.styles.tsx';

export const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [messageIsVisible, setMessageIsVisible] = useState<boolean>(true);
  const {
    workOrdersCount,
    selectWorkOrderById,
    selectWorkOrdersByPending,
    selectWorkOrdersByCompleted,
  } = useWorkOrders();

  // Pending WorkOrders
  const ids = useMemo(
    () => selectWorkOrdersByPending().map((workOrder) => workOrder.id),
    [selectWorkOrdersByPending],
  );
  const [pendingItems, setPendingItems] = useState(ids);
  const pendingItemsCount = useMemo(() => pendingItems.length, [pendingItems]);

  const completedItems = selectWorkOrdersByCompleted();
  const completedItemsCount = useMemo(
    () => completedItems.length,
    [completedItems],
  );

  return (
    <Flex direction="column" gap="10px">
      {/* Banner Message */}
      <ScaleFade initialScale={0.9} in={messageIsVisible} unmountOnExit={true}>
        <MessageBanner setMessageIsVisible={setMessageIsVisible} />
      </ScaleFade>

      {/* View Map Button */}
      <Flex direction="row" justifyContent="flex-end">
        <Button
          leftIcon={<MdMap />}
          sx={DashboardButtonMapStyle}
          onClick={onOpen}
        >
          View Map
        </Button>
      </Flex>

      {/* GoogleMapModal */}
      <GoogleMapModal isOpen={isOpen} onClose={onClose} />

      {/* Work Orders Heading and Icons */}
      <Flex direction="row" justifyContent="space-between" h="38px">
        <Heading sx={DashboardHeadingStyle}>
          My work orders ({pendingItemsCount})
        </Heading>
        <WorkOrderIcons />
      </Flex>

      {/* Completed Work Orders */}
      {completedItems.length > 0 && (
        <CompletedWorkOrdersAccordion
          workOrders={completedItems}
          totalCount={workOrdersCount}
          completedCount={completedItemsCount}
        />
      )}

      {/* WorkOrderItem */}
      <Flex direction="column">
        <Accordion sx={DashboardAccordionStyle} allowToggle>
          <Reorder.Group
            values={pendingItems}
            onReorder={setPendingItems}
            layoutScroll
          >
            {pendingItems.map((item) => (
              <ReorderWorkOrderItem
                key={item}
                item={selectWorkOrderById(item)}
              />
            ))}
          </Reorder.Group>
        </Accordion>
      </Flex>
    </Flex>
  );
};
