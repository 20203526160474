import type { SystemStyleObject } from '@chakra-ui/react';

export const TakeMeterPictureModalContentStyle: SystemStyleObject = {
  width: '100%',
  maxWidth: '100vw',
  height: '100vh',
  margin: 0,
  position: 'relative',
  overflow: 'hidden',
};

export const TakeMeterPictureModalHeaderStyle: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '#288fce',
  height: '64px',
  alignItems: 'center',
  paddingX: 4,
};

export const TakeMeterPictureModalCloseButton: SystemStyleObject = {
  background: 'transparent',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'arialMed',
  fontSize: '16px',
  _hover: {
    backgroundColor: 'transparent',
  },
};

export const TakeMeterPictureModalOverlay: SystemStyleObject = {
  position: 'absolute',
  top: '64px',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)', // Adjust the overlay color and opacity
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '2xl',
};

export const TakeMeterPictureModalGuide: SystemStyleObject = {
  width: '70%',
  height: '120px',
  border: '5px solid white',
  borderRadius: '8px',
};

export const TakeMeterPictureModalHelptextStyle: SystemStyleObject = {
  color: 'white',
  fontFamily: 'arialMed',
  fontSize: '16px',
};
