import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import {
  DigitalIdModalBoxStyle,
  DigitalIdModalCloseButtonStyle,
  DigitalIdModalContentStyle,
  DigitalIdModalOverlayStyle,
} from './DigitalId.styles.tsx';

type DigitalIdProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DigitalId = ({ isOpen, onClose }: DigitalIdProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay sx={DigitalIdModalOverlayStyle} />
      <ModalContent sx={DigitalIdModalContentStyle}>
        <Flex direction="column" alignItems="center">
          <Flex w={'100%'}>
            <Button sx={DigitalIdModalCloseButtonStyle} onClick={onClose}>
              Close
            </Button>
          </Flex>
          <Box sx={DigitalIdModalBoxStyle}>&nbsp;</Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
