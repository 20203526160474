import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * > Layer Styles allow you to save a combination of styling attributes to
 * > re-use in other components. Once created, pass the layerStyle prop to
 * > any component and chakra will resolve the styles accordingly.
 * > ...
 * > Layer Styles will not override variant styling.
 *
 * @see https://chakra-ui.com/docs/styled-system/text-and-layer-styles#layer-styles
 *
 * ```jsx
 * <Box layerStyle="shared">...and here</Box>
 * <Box layerStyle={['shared', 'anotherLayerStyle']}>multiple layerStyles</Box>
 * ```
 */
export const layerStyles: SystemStyleObject = {
  inlineFlexCentered: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  scrollbars: {
    '&::-webkit-scrollbar': {
      width: '2.5',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.300',
    },
  },

  /**
   * REVIEW which do we prefer:
   *
   * ```tsx
   * <Stack layerStyle="stackDefault" {...props} />
   * <Stack spacing="comfortable" {...props} />
   * ```
   */
  stackDefault: {
    gap: 'comfortable',
  },

  /** commonly used for clickable inputAddons */
  hoverPointer: {
    _hover: { cursor: 'pointer' },
  },
};
