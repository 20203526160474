import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const outline = defineStyle({
  borderColor: 'border',
  textTransform: 'uppercase',

  _disabled: {
    pointerEvents: 'none',
  },
});

const solid = defineStyle({
  textTransform: 'uppercase',
  fontWeight: 'medium',

  _disabled: {
    pointerEvents: 'none',
  },
});

const link = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    color: `${c}.500`,
    _dark: {
      color: `${c}.400`,
    },
    fontWeight: 'normal',
  };
});

/**
 * > The Button component is a single part component. All of the styling is
 * > applied directly to the button element.
 * @see https://chakra-ui.com/docs/components/button/theming
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
 *
 * Note that with regard to colorScheme, there is a bunch of special-casing that
 * is applied to various colors (see `accessibleColorMap`).
 */
export const buttonTheme = defineStyleConfig({
  baseStyle: {},

  defaultProps: {
    variant: 'solid',
    size: 'md',
  },

  variants: {
    solid,
    outline,
    link,
  },

  sizes: {
    xs: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    sm: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    md: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    lg: {
      borderRadius: 'base',
    },
  },
});
