import { Flex } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import meterImage1 from 'assets/meterImage1.png';
import meterImage2 from 'assets/meterImage2.png';
import meterImage3 from 'assets/meterImage3.png';

import { WorkOrderImageCarouselStyle } from './WorkOrderImageCarousel.styles.tsx';

type WorkOrderImageCarouselProps = {
  onImageClick: (picture: string) => void;
};

export const WorkOrderImageCarousel = ({
  onImageClick,
}: WorkOrderImageCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isDownRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleMouseDown = (e: MouseEvent) => {
        isDownRef.current = true;
        container.classList.add('active');
        startXRef.current = e.pageX - container.offsetLeft;
        scrollLeftRef.current = container.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDownRef.current = false;
        container.classList.remove('active');
      };

      const handleMouseUp = () => {
        isDownRef.current = false;
        container.classList.remove('active');
      };

      const handleMouseMove = (e: MouseEvent) => {
        if (!isDownRef.current) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = (x - startXRef.current) * 2; // Adjust the scroll speed
        container.scrollLeft = scrollLeftRef.current - walk;
      };

      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mouseleave', handleMouseLeave);
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mousemove', handleMouseMove);

      return () => {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mouseleave', handleMouseLeave);
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);
  return (
    <Flex sx={WorkOrderImageCarouselStyle} ref={containerRef}>
      <img
        src={meterImage1}
        alt="Meter Image 1"
        onClick={() => onImageClick(meterImage1)}
      />
      <img
        src={meterImage2}
        alt="Meter Image 2"
        onClick={() => onImageClick(meterImage2)}
      />
      <img
        src={meterImage3}
        alt="Meter Image 3"
        onClick={() => onImageClick(meterImage3)}
      />
      <img
        src={meterImage1}
        alt="Meter Image 4"
        onClick={() => onImageClick(meterImage1)}
      />
      <img
        src={meterImage2}
        alt="Meter Image 5"
        onClick={() => onImageClick(meterImage2)}
      />
    </Flex>
  );
};
