import { type SystemStyleObject } from '@chakra-ui/react';
import { type CSSProperties } from 'react';

const brandBlue = 'var(--chakra-colors-brandBlue)';

export const WorkOrderFlexContainerStyle: SystemStyleObject = {
  flexDirection: 'column',
  gap: '12px',
  marginBottom: '100px',
};

export const WorkOrderFlexGlassStyle: SystemStyleObject = {
  padding: '24px',
  borderRadius: '10px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  border: 'solid 1px rgba(255, 255, 255, 0.5)',
  backgroundColor: 'rgba(255, 255, 255, 0.45)',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const WorkOrderIconStyle: SystemStyleObject = {
  color: brandBlue,
  fontSize: '40px',
  cursor: 'pointer',
};

export const WorkOrderHeadingStyle: SystemStyleObject = {
  fontFamily: 'arialMed',
  fontSize: '28px',
  color: brandBlue,
};

export const WorkOrderMapStyle: CSSProperties = {
  minHeight: '300px',
  borderRadius: '10px',
};

export const WorkOrderDetailsBoxStyle: SystemStyleObject = {
  color: brandBlue,
};

export const WorkOrderHazardTextStyle: SystemStyleObject = {
  fontFamily: 'arialBold',
  fontSize: '12px',
  fontWeight: 'bold',
  color: brandBlue,
};

export const WorkOrderHazardIconStyle: SystemStyleObject = {
  width: '26px',
  height: '26px',
  color: brandBlue,
};

export const WorkOrderHazardSelectTextStyle: SystemStyleObject = {
  fontSize: '14px',
  color: brandBlue,
};

export const WorkOrderHazardSelectHelpTextStyle: SystemStyleObject = {
  fontSize: '12px',
  color: '#718096',
};

export const WorkOrderSubheadingStyle: SystemStyleObject = {
  fontSize: '20px',
  color: brandBlue,
  fontFamily: 'arialMed',
};

export const WorkOrderMeterReadingPictureButtonStyle: SystemStyleObject = {
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: 'arialMed',
  fontSize: '18px',
  color: 'white',
  backgroundColor: '#0057ff',
  transition: '0.3s all linear',
  _hover: {
    backgroundColor: '#00379BFF',
  },
};

export const WorkOrderStickyActionbarStyle: SystemStyleObject = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '90px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  backgroundColor: 'rgba(255, 255, 255, 0.45)',
  paddingY: '20px',
  justifyContent: 'center',
};

export const WorkOrderStickyActionbarInnerStyle: SystemStyleObject = {
  flexDirection: 'row',
  gap: 5,
  justifyContent: 'center',
  maxWidth: '728px',
  width: '100%',
};

export const WorkOrderCompleteButtonStyle: SystemStyleObject = {
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: 'arialMed',
  fontSize: '18px',
  color: 'white',
  backgroundColor: '#0057ff',
  transition: '0.3s all linear',
  _hover: {
    backgroundColor: '#00379BFF',
  },
  flexGrow: 2,
  paddingY: 3,
  height: '100%',
  maxHeight: '48px',
};

export const WorkOrderInterruptButtonStyle: SystemStyleObject = {
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: 'arialMed',
  fontSize: '18px',
  color: 'white',
  backgroundColor: '#c53030',
  transition: '0.3s all linear',
  _hover: {
    backgroundColor: '#9a2424',
  },
  paddingY: 3,
  height: '100%',
  maxHeight: '48px',
};

export const WorkOrderMeterReadSubtextStyle: SystemStyleObject = {
  fontFamily: 'arialReg',
  fontSize: ' 14px',
};

export const WorkOrderPreviousMeterReadStyle: SystemStyleObject = {
  borderRadius: '4px',
  boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
  border: '1px solid #fff',
  backgroundColor: '#cbd5e0',
  color: '#a0aec0',
  fontSize: '40px',
  maxWidth: '265px',
  minWidth: '265px',
  alignItems: 'self-end',
};

export const WorkOrderPreviousMeterReadTextStyle: SystemStyleObject = {
  width: '100%',
  textAlign: 'center',
  transform: 'scaleX(1.4) scaleY(1.4)',
  transformOrigin: 'center',
  fontFamily: 'urwdinMed',
  userSelect: 'none',
};

export const WorkOrderPreviousMeterReadBlackBoxStyle: SystemStyleObject = {
  border: 'solid 1px #fff',
  backgroundColor: 'black',
  paddingX: '8px',
  width: '180px',
  borderRadius: '4px',
};

export const WorkOrderPreviousMeterReadBlackTextStyle: SystemStyleObject = {
  color: 'white',
  fontSize: '40px',
  textAlign: 'center',
  transformOrigin: 'center',
  fontFamily: 'urwdinMed',
  userSelect: 'none',
  transform: 'scaleX(1.4) scaleY(1.4)',
};

export const WorkOrderPreviousMeterReadRedBoxStyle: SystemStyleObject = {
  border: 'solid 1px #fff',
  backgroundColor: '#e53e3e',
  paddingX: '8px',
  width: '108px',
  borderRadius: '4px',
};

export const WorkOrderPreviousMeterReadRedTextStyle: SystemStyleObject = {
  color: 'white',
  fontSize: '40px',
  textAlign: 'center',
  transformOrigin: 'center',
  fontFamily: 'urwdinMed',
  userSelect: 'none',
  transform: 'scaleX(1.4) scaleY(1.4)',
};

export const WorkOrderMeterPictureHelptextStyle: SystemStyleObject = {
  fontFamily: 'arialReg',
  fontSize: '14px',
  color: '#718096',
};

export const ConfirmReadingModalFooterStyle: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderTop: '0',
  '> button': {
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: 'arialMed',
    fontSize: '18px',
    color: 'white',
    backgroundColor: '#52c7e6',
    transition: '0.3s all linear',
    _hover: {
      backgroundColor: '#39899F',
    },
  },
};

export const ConfirmReadingModalHeaderStyle: SystemStyleObject = {
  borderBottom: '0',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
};

export const ConfirmReadingModalOverlayStyle: SystemStyleObject = {
  backdropFilter: 'blur(10px)',
};

export const WorkOrderCompletedBannerStyle: SystemStyleObject = {
  borderRadius: '10px',
  backgroundColor: '#38a169',
  color: 'white',
  padding: '16px',
};

export const WorkOrderInterruptedBannerStyle: SystemStyleObject = {
  borderRadius: '10px',
  backgroundColor: '#e53e3e',
  color: 'white',
  padding: '16px',
};
