import { type SystemStyleObject } from '@chakra-ui/react';

const brandBlue = 'var(--chakra-colors-brandBlue)';
export const WorkOrderSliderTextColor: SystemStyleObject = {
  fontFamily: 'arialBold',
  fontSize: '14px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: brandBlue,
};

export const WorkOrderSliderTrackStyle: SystemStyleObject = {
  height: '15px',
  background: 'linear-gradient(to right, red, yellow, green)',
  boxShadow: 'inset 0 1px 3px 0 #4a5568;',
};

export const WorkOrderSliderThumbStyle: SystemStyleObject = {
  border: '1px solid white',
  backgroundColor: '#52c7e6',
};
