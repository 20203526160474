import { type SystemStyleObject } from '@chakra-ui/react';
import { type CSSProperties } from 'react';

export const HeaderBoxStyle: SystemStyleObject = {
  background: 'rgba(255, 255, 255, 0.55)',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  backdropFilter: 'blur(21px)',
  padding: '16px 24px',
  borderRadius: '10px',

  // workaround: trigger a new stacking context to prevent the User Menu
  // dropdown being covered by later elements, bc they use backdrop-filter which
  // triggers a new stacking context
  zIndex: '1',
};

export const HeaderLogoStyle: CSSProperties = {
  maxWidth: '230px',
};

export const HeaderFlexStyle: SystemStyleObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const UserFlexStyle: SystemStyleObject = {
  gap: '8px',
  alignItems: 'center',
};

export const UserMenuListStyle: SystemStyleObject = {
  paddingTop: 0,
  paddingBottom: 0,
};

export const UserMenuButtonStyle: SystemStyleObject = {
  backgroundColor: 'transparent !important',
  padding: '0',
  minWidth: '5px',
};

export const UserMenuItemStyle: SystemStyleObject = {
  fontFamily: 'body',
  fontSize: 'sm',
  color: '#1a202c',
  width: '144px',
  borderBottom: '1px solid #cbd5e0',
};
