import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import {
  GoogleMapModalCloseButton,
  GoogleMapModalContentStyle,
  GoogleMapModalHeaderStyle,
  GoogleMapModalImage,
} from './GoogleMapModal.styles.tsx';

type GoogleMapModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GoogleMapModal = ({ isOpen, onClose }: GoogleMapModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={GoogleMapModalContentStyle} className={'testModal'}>
        <Flex direction="column">
          <Flex sx={GoogleMapModalHeaderStyle}>
            <Button onClick={onClose} sx={GoogleMapModalCloseButton}>
              Close
            </Button>
          </Flex>
        </Flex>
        <Box sx={GoogleMapModalImage}>&nbsp;</Box>
      </ModalContent>
    </Modal>
  );
};
