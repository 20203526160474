import environmentSettings from './environments';

const BASE_API_ORIGIN = 'http://localhost:8000';

/**
 * Base settings
 *
 * When adding new settings on a per-env basis, ensure that they're added here
 * first to ensure type safety and assist IDE integration.
 */
export const baseSettings = {
  ENVIRONMENT: import.meta.env.MODE,

  BASE_API_ORIGIN,

  // axiosInstance provides this as baseURL
  BASE_API_URL: `${BASE_API_ORIGIN}/api/example/v1`,

  // these URLs are NOT based on BASE_API_URL, so are composed after merging
  // environmentSettings; they are stubbed here for the sake of TS
  AUTH_URL: '', // composed below
  FILE_UPLOAD_URL: '', // composed below

  // these URLs are composed with axios.config.baseURL == BASE_API_URL
  SCAN_FILE_URI: '/scan_file/',

  CSRF_COOKIE_NAME: 'csrftoken',

  GOOGLEMAPS_API_KEY: 'TODO',
  SENTRY_DSN: 'TODO',
};

/** App settings with per-env overrides applied  */
export const settings = {
  ...baseSettings,
  ...environmentSettings?.[
    import.meta.env.MODE as keyof typeof environmentSettings
  ],
};

// these variables require environmentSettings to be merged before they can be
// composed
settings.BASE_API_URL = `${settings.BASE_API_ORIGIN}/api/example/v1`;
settings.AUTH_URL = `${settings.BASE_API_ORIGIN}/api/auth/v1/`;
settings.FILE_UPLOAD_URL = `${settings.BASE_API_ORIGIN}/api/br_filer/v1/file`;

export type Settings = typeof baseSettings;
