import { type SystemStyleObject } from '@chakra-ui/react';

export const CompletedWorkOrderAccordionStyle: SystemStyleObject = {
  background: 'transparent',
  border: 0,
};

export const CompletedWorkOrderAccordianItemStyle: SystemStyleObject = {
  backgroundColor: '#0033a0',
  borderRadius: '10px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #0033a0, inset 0 1px 37px 0 #0f275b',
};

export const CompletedWorkOrderAccordionHeaderStyle: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
};

export const CompletedWorkOrderAccordionHeadingStyle: SystemStyleObject = {
  fontFamily: 'arialMed',
  fontSize: '16px',
  color: 'white',
  fontWeight: 'normal',
};

export const CompletedWorkOrderAccordionCountTextStyle: SystemStyleObject = {
  color: 'white',
  fontFamily: 'arialReg',
  fontWeight: 'bold',
  fontSize: '20px',
};

export const CompletedWorkOrderAccordionInnerStyle: SystemStyleObject = {
  borderRadius: '10px',
  border: 0,
  backgroundColor: 'transparent',
};
