import { type SystemStyleObject } from '@chakra-ui/react';

import snowflake from 'assets/snowflake.png';

export const MessageBannerBox: SystemStyleObject = {
  borderRadius: '10px',
  background: `url("${snowflake}") no-repeat, #f56565`,
};

export const MessageBannerFlexOuter: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px 8px 20px 20px',
};

export const MessageBannerFlexInner: SystemStyleObject = {
  flexDirection: 'column',
};

export const MessageBannerText: SystemStyleObject = {
  fontFamily: 'arialBold',
  color: 'white',
  fontSize: '16px',
};

export const MessageBannerIcon: SystemStyleObject = {
  color: 'white',
  cursor: 'pointer',
};
