import type { SystemStyleObject } from '@chakra-ui/react';

export const WorkOrderImageCarouselStyle: SystemStyleObject = {
  maxWidth: '680px',
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  WebkitOverflowScrolling: 'touch',
  '::-webkit-scrollbar': {
    width: 0,
    background: 'transparent',
  },
  '> img': {
    height: '140px',
    maxWidth: '200px',
    userSelect: 'none',
    WebkitUserDrag: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    cursor: 'pointer',
  },
};
