import { Button, Flex, Text, Textarea } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import {
  WorkOrderCommentsAuthorStyle,
  WorkOrderCommentsContainerStyle,
  WorkOrderCommentsHeadingStyle,
  WorkOrderCommentsTextStyle,
  WorkOrderCommentsTextareaStyle,
  WorkOrderCommmentsSaveButtonStyle,
} from './WorkOrderComments.styles.tsx';
import {
  type WorkOrderCommentType,
  WorkOrderComments as WorkOrderCommentsData,
} from '../../fixtures/WorkOrderComments';

export const WorkOrderComments = () => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [comments, setComments] = useState<WorkOrderCommentType[]>(
    [...WorkOrderCommentsData].sort((a, b) => b.id - a.id),
  );
  const addComment = () => {
    if (textareaRef.current) {
      const newCommentText = textareaRef.current.value;
      if (newCommentText.trim() !== '') {
        const newComment: WorkOrderCommentType = {
          id: comments.length + 1,
          comment: newCommentText,
          author: 'Anthony Higginbottom',
        };
        setComments((prevComments) =>
          [...prevComments, newComment].sort((a, b) => b.id - a.id),
        );
        textareaRef.current.value = '';
      }
    }
  };

  return (
    <Flex direction="column" gap={2}>
      {/* Heading */}
      <Flex direction="row">
        <Text sx={WorkOrderCommentsHeadingStyle}>Enter a comment</Text>
      </Flex>

      {/* Add Comment */}
      <Flex direction="row" gap="30px">
        <Textarea
          ref={textareaRef}
          sx={WorkOrderCommentsTextareaStyle}
        ></Textarea>
        <Button onClick={addComment} sx={WorkOrderCommmentsSaveButtonStyle}>
          Save
        </Button>
      </Flex>

      {/* Comments */}
      {comments.map(
        (
          comment: any, // eslint-disable-line @typescript-eslint/no-explicit-any
          index: number,
        ) => (
          <Flex
            direction="column"
            sx={WorkOrderCommentsContainerStyle}
            gap={1}
            key={`comment_${index}`}
          >
            <Flex
              direction="row"
              sx={WorkOrderCommentsAuthorStyle}
            >{`${comment.author}, 05/08/2024 11:00`}</Flex>
            <Flex direction="row" sx={WorkOrderCommentsTextStyle}>
              {comment.comment}
            </Flex>
          </Flex>
        ),
      )}
    </Flex>
  );
};
