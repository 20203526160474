import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdCheckCircleOutline, MdChevronLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';

import { useWorkOrders } from 'api';
import meterMap from 'assets/metermap.jpg';
import {
  TakeMeterPictureModal,
  WorkOrderComments,
  WorkOrderImageCarousel,
  WorkOrderMeterReadingModal,
  WorkOrderSlider,
} from 'components';
import { WorkOrderImageModal } from 'components/WorkOrderImageModal';

import {
  ConfirmReadingModalFooterStyle,
  ConfirmReadingModalHeaderStyle,
  ConfirmReadingModalOverlayStyle,
  WorkOrderCompleteButtonStyle,
  WorkOrderCompletedBannerStyle,
  WorkOrderDetailsBoxStyle,
  WorkOrderFlexContainerStyle,
  WorkOrderFlexGlassStyle,
  WorkOrderHazardIconStyle,
  WorkOrderHazardSelectHelpTextStyle,
  WorkOrderHazardSelectTextStyle,
  WorkOrderHazardTextStyle,
  WorkOrderHeadingStyle,
  WorkOrderIconStyle,
  WorkOrderInterruptButtonStyle,
  WorkOrderInterruptedBannerStyle,
  WorkOrderMapStyle,
  WorkOrderMeterPictureHelptextStyle,
  WorkOrderMeterReadSubtextStyle,
  WorkOrderMeterReadingPictureButtonStyle,
  WorkOrderPreviousMeterReadBlackBoxStyle,
  WorkOrderPreviousMeterReadBlackTextStyle,
  WorkOrderPreviousMeterReadRedBoxStyle,
  WorkOrderPreviousMeterReadRedTextStyle,
  WorkOrderPreviousMeterReadStyle,
  WorkOrderPreviousMeterReadTextStyle,
  WorkOrderStickyActionbarInnerStyle,
  WorkOrderStickyActionbarStyle,
  WorkOrderSubheadingStyle,
} from './WorkOrder.styles.tsx';

export const WorkOrder = () => {
  const navigate = useNavigate();
  const { workorderId } = useParams();
  const { updateWorkOrder, selectWorkOrderById } = useWorkOrders();
  const {
    isOpen: isPictureOpen,
    onOpen: onPictureOpen,
    onClose: onPictureClose,
  } = useDisclosure();
  const {
    isOpen: isMeterReadingOpen,
    onOpen: onMeterReadingOpen,
    onClose: onMeterReadingClose,
  } = useDisclosure();
  const {
    isOpen: isImageViewerOpen,
    onOpen: onImageViewerOpen,
    onClose: onImageViewerClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();
  const [meterReading, setMeterReading] = useState<string>('00000000');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  const workOrder = useMemo(() => {
    if (workorderId === undefined) {
      return undefined;
    }
    return selectWorkOrderById(Number(workorderId));
  }, [workorderId, selectWorkOrderById]);

  if (!workOrder) {
    navigate(-1);
  }

  useEffect(() => {
    setMeterReading('00000000');
  }, [setMeterReading, workOrder]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const preventDefault = (e: Event) => e.preventDefault();

      container.addEventListener('touchmove', preventDefault, {
        passive: false,
      });

      return () => {
        container.removeEventListener('touchmove', preventDefault);
      };
    }
  }, []);

  const handleImageClick = (picture: string) => {
    setSelectedImage(picture);
    onImageViewerOpen();
  };

  const handleCompleteWorkOrder = () => {
    if (meterReading === '00000000') {
      return;
    }

    if (Number(meterReading) < Number(workOrder?.previousReading)) {
      return onConfirmationOpen();
    }

    handleBypassLesserReading();
  };

  const handleInterruptWorkOrder = () => {
    if (!workOrder?.id) {
      return;
    }
    updateWorkOrder(workOrder?.id, workOrder?.previousReading, 'interrupted');
    return navigate('/dashboard');
  };

  const handleBypassLesserReading = () => {
    if (!workOrder?.id) {
      return;
    }
    updateWorkOrder(workOrder?.id, meterReading, 'completed');
    return navigate('/dashboard');
  };

  const workOrderIsLocked = workOrder?.status !== 'pending';
  const workOrderIsCompleted = workOrder?.status === 'completed';
  const workOrderIsInterrupted = workOrder?.status === 'interrupted';

  return (
    <>
      <Flex sx={WorkOrderFlexContainerStyle}>
        {workOrderIsCompleted && (
          <Box sx={WorkOrderCompletedBannerStyle}>
            Work order completed on 12/03/2024, 1:52PM
          </Box>
        )}
        {workOrderIsInterrupted && (
          <Box sx={WorkOrderInterruptedBannerStyle}>
            Work order interrupted. The Field Service Technician could not find
            the meter.
          </Box>
        )}
        <Flex sx={WorkOrderFlexGlassStyle}>
          <Flex direction="column" gap={5}>
            <Flex direction="row" gap="16px" alignItems="center">
              <Icon
                as={MdChevronLeft}
                sx={WorkOrderIconStyle}
                onClick={() => navigate('/dashboard')}
              />
              <Heading sx={WorkOrderHeadingStyle}>
                Work Order #{workOrder?.id}
              </Heading>
            </Flex>
            <Box sx={WorkOrderDetailsBoxStyle}>
              <Text>{`${workOrder?.address}, ${workOrder?.city} ${workOrder?.postcode}`}</Text>
              <Text>Anthony McLeod</Text>
              <Text>0412 123 123</Text>
            </Box>
            <Flex direction="row" gap="20px">
              {workOrder?.hazards.map(
                (
                  hazard: any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  index: number,
                ) => (
                  <Flex direction="column" alignItems="center" key={index}>
                    <Icon as={hazard[0]} sx={WorkOrderHazardIconStyle} />
                    <Text sx={WorkOrderHazardTextStyle}>{hazard[1]}</Text>
                  </Flex>
                ),
              )}
            </Flex>
            <Flex direction="column" gap="6px">
              <Text
                sx={WorkOrderHazardSelectTextStyle}
              >{`Hazard (${workOrder?.hazards.length})`}</Text>
              <Select w="218px">
                <option value="option1">Select</option>
                <option value="option2">Dog</option>
                <option value="option3">Snake</option>
              </Select>
              <Text sx={WorkOrderHazardSelectHelpTextStyle}>
                Select to adjust the hazard/s
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <img
              alt="Profile Picture"
              src={meterMap}
              style={WorkOrderMapStyle}
            />
          </Flex>
        </Flex>
        <Flex sx={WorkOrderFlexGlassStyle}>
          <Flex direction="column" gap="18px" w="100%">
            <Heading sx={WorkOrderSubheadingStyle}>Meter reading</Heading>
            <Flex direction="row" justifyContent="space-between">
              <Flex direction="column" gap="5px" flexGrow={'1'}>
                <Flex
                  direction="row"
                  gap="4px"
                  onClick={onMeterReadingOpen}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box sx={WorkOrderPreviousMeterReadBlackBoxStyle}>
                    <Text sx={WorkOrderPreviousMeterReadBlackTextStyle}>
                      {meterReading.slice(0, 5)}
                    </Text>
                  </Box>
                  <Box sx={WorkOrderPreviousMeterReadRedBoxStyle}>
                    <Text sx={WorkOrderPreviousMeterReadRedTextStyle}>
                      {meterReading.slice(5)}
                    </Text>
                  </Box>
                </Flex>
                <Text sx={WorkOrderMeterReadSubtextStyle}>
                  Click on the input to manually adjust
                </Text>
              </Flex>
              <Flex
                direction="column"
                gap="5px"
                flexGrow={'1'}
                alignItems="flex-end"
              >
                <Box sx={WorkOrderPreviousMeterReadStyle}>
                  <Text sx={WorkOrderPreviousMeterReadTextStyle}>
                    {workOrder?.previousReading}
                  </Text>
                </Box>
                <Text sx={WorkOrderMeterReadSubtextStyle}>
                  Previous meter reading
                </Text>
              </Flex>
            </Flex>
            <Button
              sx={WorkOrderMeterReadingPictureButtonStyle}
              onClick={onPictureOpen}
              isDisabled={workOrderIsLocked}
            >
              Take a picture
            </Button>
            <TakeMeterPictureModal
              isOpen={isPictureOpen}
              onClose={onPictureClose}
            />
          </Flex>
        </Flex>
        <Flex sx={WorkOrderFlexGlassStyle}>
          <Flex direction="column" gap="18px" w="100%">
            <Flex direction="row" justifyContent="space-between" w="100%">
              <Heading sx={WorkOrderSubheadingStyle}>
                Meter reading pictures
              </Heading>
              <Text sx={WorkOrderMeterPictureHelptextStyle}>
                Click on a picture for full size view.
              </Text>
            </Flex>
            <WorkOrderImageCarousel onImageClick={handleImageClick} />
          </Flex>
        </Flex>
        <Flex sx={WorkOrderFlexGlassStyle}>
          <Flex direction="column" w="100%" gap="25px">
            <Heading sx={WorkOrderSubheadingStyle}>Meter condition</Heading>
            <WorkOrderSlider />
            <WorkOrderComments />
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={WorkOrderStickyActionbarStyle}>
        <Flex sx={WorkOrderStickyActionbarInnerStyle}>
          <Button
            sx={WorkOrderCompleteButtonStyle}
            onClick={handleCompleteWorkOrder}
            isDisabled={workOrderIsLocked}
          >
            Complete order
          </Button>
          <Button
            sx={WorkOrderInterruptButtonStyle}
            onClick={handleInterruptWorkOrder}
            isDisabled={workOrderIsLocked}
          >
            I can&apos;t find the meter
          </Button>
        </Flex>
      </Flex>
      <WorkOrderMeterReadingModal
        isOpen={isMeterReadingOpen}
        onClose={onMeterReadingClose}
        saveReading={(v) => {
          setMeterReading(v);
          onMeterReadingClose();
        }}
      />
      <WorkOrderImageModal
        isOpen={isImageViewerOpen}
        onClose={onImageViewerClose}
        selectedImage={selectedImage}
        address={`${workOrder?.address}, ${workOrder?.city} ${workOrder?.postcode}`}
      />
      {/* Confirm lesser reading modal */}
      <Modal isOpen={isConfirmationOpen} onClose={onConfirmationClose}>
        <ModalOverlay sx={ConfirmReadingModalOverlayStyle} />
        <ModalContent>
          <ModalHeader sx={ConfirmReadingModalHeaderStyle}>
            <Icon as={MdCheckCircleOutline} color="#718096" />
            Confirmation request
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody minHeight={'150px'}>
            <Text>
              The meter reading entered is a lesser value than the historical
              previous one. Please confirm the value.
            </Text>
          </ModalBody>

          <ModalFooter sx={ConfirmReadingModalFooterStyle}>
            <Button onClick={onConfirmationClose}>Cancel</Button>
            <Button
              onClick={() => {
                onConfirmationClose();
                handleBypassLesserReading();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
