import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

import {
  CompletedWorkOrderAccordianItemStyle,
  CompletedWorkOrderAccordionCountTextStyle,
  CompletedWorkOrderAccordionHeaderStyle,
  CompletedWorkOrderAccordionHeadingStyle,
  CompletedWorkOrderAccordionInnerStyle,
  CompletedWorkOrderAccordionStyle,
} from './CompletedWorkOrdersAccordion.styles.tsx';
import { type WorkOrderType } from '../../fixtures/WorkOrders.tsx';
import { WorkOrderItem } from '../WorkOrderItem';

type CompletedWorkOrdersAccordionProps = {
  workOrders: WorkOrderType[];
  totalCount: number;
  completedCount: number;
};

export const CompletedWorkOrdersAccordion = ({
  workOrders,
  totalCount,
  completedCount,
}: CompletedWorkOrdersAccordionProps) => {
  return (
    <Accordion allowToggle sx={CompletedWorkOrderAccordionStyle}>
      <AccordionItem sx={CompletedWorkOrderAccordianItemStyle}>
        <AccordionButton>
          <Flex sx={CompletedWorkOrderAccordionHeaderStyle}>
            <Heading sx={CompletedWorkOrderAccordionHeadingStyle}>
              Completed work orders
            </Heading>
            <Flex direction="row" gap="30px" alignItems="center">
              <Text
                sx={CompletedWorkOrderAccordionCountTextStyle}
              >{`${completedCount}/${totalCount}`}</Text>
              <AccordionIcon color="white" />
            </Flex>
          </Flex>
        </AccordionButton>
        <AccordionPanel pt={0}>
          <Accordion sx={CompletedWorkOrderAccordionInnerStyle} allowToggle>
            {workOrders.map((item) => (
              <WorkOrderItem
                key={`completedWorkOrder_${item.id}`}
                data={item}
                onPointerDown={() => {}}
              />
            ))}
          </Accordion>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
