import { type SystemStyleObject } from '@chakra-ui/react';

export const WorkOrderMeterReadingModalBodyStyle: SystemStyleObject = {
  minHeight: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const WorkOrderMeterReadingModalFooterStyle: SystemStyleObject = {
  justifyContent: 'space-between',
  borderTop: 0,
  '> button': {
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: 'arialMed',
    fontSize: '18px',
    color: 'white',
    backgroundColor: '#0057ff',
    transition: '0.3s all linear',
    _hover: {
      backgroundColor: '#00379BFF',
    },
    minWidth: '120px',
  },
};

export const WorkOrderMeterReadingHeaderStyle: SystemStyleObject = {
  borderBottom: 0,
};

export const WorkOrderMeterReadingPinContainerStyle: SystemStyleObject = {
  '> input': {
    width: '3.5rem',
    height: '4rem',
    fontSize: '24px',
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white',
  },
  '> input:nth-last-of-type(-n+3)': {
    backgroundColor: '#e53e3e',
    color: 'white',
  },
};

export const WorkOrderMeterReadingOverlayStyle: SystemStyleObject = {
  backdropFilter: 'blur(10px)',
};
