import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { MdOutlineClear } from 'react-icons/md';

import {
  MessageBannerBox,
  MessageBannerFlexInner,
  MessageBannerFlexOuter,
  MessageBannerIcon,
  MessageBannerText,
} from './MessageBanner.styles.tsx';

type MessageBannerProps = {
  setMessageIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MessageBanner = ({ setMessageIsVisible }: MessageBannerProps) => {
  return (
    <Box sx={MessageBannerBox}>
      <Flex sx={MessageBannerFlexOuter}>
        <Flex sx={MessageBannerFlexInner}>
          <Text sx={MessageBannerText}>
            Severe weather warning!
            <br />
            Frost Warning for Wednesday for Perth, Bunbury Geographe & Leeuwin
            coasts
          </Text>
        </Flex>
        <Icon
          sx={MessageBannerIcon}
          as={MdOutlineClear}
          onClick={() => setMessageIsVisible(false)}
        >
          Close
        </Icon>
      </Flex>
    </Box>
  );
};
