import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { type RouteObject, createHashRouter, redirect } from 'react-router-dom';

import { MainLayout } from './layouts';
import { Dashboard, MyProfile, WorkOrder } from './screens';
import { TrackNavigation } from './util/useTracking.tsx';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <TrackNavigation />,
    children: [
      {
        element: <MainLayout />,
        children: [
          { index: true, loader: () => redirect('/dashboard') },
          {
            path: 'dashboard',
            element: <Dashboard />,
            handle: { title: 'Dashboard' },
          },
          {
            path: 'workorder',
            children: [
              {
                index: true,
                loader: () => redirect('/dashboard'),
              },
              {
                path: ':workorderId',
                element: <WorkOrder />,
                handle: { title: 'Work Order' },
              },
            ],
          },
          {
            path: 'profile',
            element: <MyProfile />,
            handle: { title: 'My Profile' },
          },
        ],
      },
    ],
  },
];

const DevScratchpad = lazy(() => import('screens/dev/Dev'));
if (import.meta.env.DEV)
  routes.push({
    path: '/dev',
    element: <DevScratchpad />,
  });

/** https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/ */
const sentryCreateHashRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);

/**
 * We're using HashRouter because we don't currently have devops
 * support for BrowserRouter: our reverse proxy doesn't rewrite
 * deep app URLs to index.html, so they 404 on direct access.
 *
 * Note that HashRouter has no need of the `basename` option.
 */
const router = sentryCreateHashRouter(routes);

export default router;
