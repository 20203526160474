import { type Settings } from 'settings';

/** staging settings overrides */
export const settings: Partial<Settings> = {
  BASE_API_ORIGIN: 'https://staging.example.com',
  CSRF_COOKIE_NAME: 'examplecsrfstaging',
  GOOGLEMAPS_API_KEY: '',
};

export default settings;
