import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import {
  WorkOrderImageModalCloseButton,
  WorkOrderImageModalContentStyle,
  WorkOrderImageModalHeaderStyle,
  WorkOrderImageModalSubHeaderStyle,
} from './WorkOrderImageModal.styles.tsx';

type WorkOrderImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: string;
  address: string;
};

export const WorkOrderImageModal = ({
  isOpen,
  onClose,
  selectedImage,
  address,
}: WorkOrderImageModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={WorkOrderImageModalContentStyle}>
        <Flex direction="column">
          <Flex direction="column">
            <Flex sx={WorkOrderImageModalHeaderStyle}>
              <Button onClick={onClose} sx={WorkOrderImageModalCloseButton}>
                Close
              </Button>
            </Flex>
            <Flex sx={WorkOrderImageModalSubHeaderStyle}>
              <Text>Gallery</Text>
              <Text>{`${address} - 18 March 09:32AM`}</Text>
            </Flex>
          </Flex>
          <img src={selectedImage} alt="Selected Image" />
        </Flex>
      </ModalContent>
    </Modal>
  );
};
