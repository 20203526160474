import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = {
  color: 'bodyText',
  fontWeight: 'bold',
};

const lightBlueVariant = {
  color: 'link.500',
  fontWeight: 'thin',
};

const textVariant = {
  color: 'bodyText',
  fontWeight: 'normal',
};

export const linkTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,

  variants: {
    text: textVariant,
    lightBlue: lightBlueVariant,
  },

  defaultProps: {},
});
