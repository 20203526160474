import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdDragIndicator, MdOutlineFlag } from 'react-icons/md';
import { useNavigate } from 'react-router';

import {
  WordOrderPanelSubtitle,
  WorkOrderAddressStyle,
  WorkOrderArrowsStyle,
  WorkOrderFlexDistanceStyle,
  WorkOrderFlexHeadingStyle,
  WorkOrderFlexLeftStyle,
  WorkOrderFlexRightStyle,
  WorkOrderGoogleMapButtonStyle,
  WorkOrderHazardTextStyle,
  WorkOrderItemBox,
  WorkOrderPanelStreetView,
  WorkOrderPanelStyle,
  WorkOrderPriorityFlagStyle,
  WorkOrderStartButton,
  WorkOrderStatusTextStyle,
} from './WorkOrderItem.styles.tsx';
import { type WorkOrderType } from '../../fixtures/WorkOrders.tsx';

type WorkOrderItemProps = {
  data: WorkOrderType;
  onPointerDown: (event: PointerEvent | React.PointerEvent<Element>) => void;
};

export const WorkOrderItem = ({ data, onPointerDown }: WorkOrderItemProps) => {
  const navigate = useNavigate();
  const [startButton, setStartButton] = useState<string>(
    data.status === 'pending' ? 'En Route' : 'View',
  );

  const handleStartButton = () => {
    if (startButton == 'En Route') {
      setStartButton('Start work order');
    } else {
      navigate(`/workorder/${data.id}`);
    }
  };

  return (
    <AccordionItem
      sx={{
        ...WorkOrderItemBox,
        backgroundColor:
          data.status === 'pending'
            ? 'rgba(255, 255, 255, 0.45)'
            : 'rgba(255, 255, 255, 0.9)',
      }}
    >
      <Flex sx={WorkOrderFlexHeadingStyle}>
        <Flex sx={WorkOrderFlexLeftStyle} alignItems="center">
          <Flex sx={WorkOrderArrowsStyle} onPointerDown={onPointerDown}>
            {data.status === 'pending' && <Icon as={MdDragIndicator} />}
          </Flex>
        </Flex>
        <AccordionButton p={0} w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex direction="row" gap="24px">
              <Flex w="26px" alignItems="center">
                {data.priority ? (
                  <Icon as={MdOutlineFlag} sx={WorkOrderPriorityFlagStyle} />
                ) : (
                  <Box w="26px"></Box>
                )}
              </Flex>
              <Text sx={WorkOrderAddressStyle}>
                {data.address}
                <br />
                {data.city} {data.postcode}
              </Text>
            </Flex>
            <Flex sx={WorkOrderFlexRightStyle}>
              <Flex direction="row" gap={5} alignItems="center">
                {data.status === 'pending' ? (
                  <>
                    <Flex direction="row" gap={2}>
                      {data.hazards.map(
                        (
                          hazard: any, // eslint-disable-line @typescript-eslint/no-explicit-any
                          index: number,
                        ) => (
                          <Icon
                            key={index}
                            as={hazard[0]}
                            sx={WorkOrderPriorityFlagStyle}
                          />
                        ),
                      )}
                    </Flex>
                    <Flex sx={WorkOrderFlexDistanceStyle}>
                      <Text sx={{ fontWeight: 'bold' }}>{data.distance}</Text>
                      <Text>&nbsp;km</Text>
                    </Flex>
                  </>
                ) : (
                  <Text
                    sx={{
                      ...WorkOrderStatusTextStyle,
                      color:
                        data.status === 'completed' ? '#38a169' : '#e53e3e',
                    }}
                  >
                    {data.status}
                  </Text>
                )}

                <Icon as={data.type} sx={WorkOrderPriorityFlagStyle} />
              </Flex>
              <AccordionIcon color="var(--chakra-colors-brandBlue)" />
            </Flex>
          </Flex>
        </AccordionButton>
      </Flex>

      <AccordionPanel sx={WorkOrderPanelStyle}>
        <Flex direction="row" w="100%" gap="30px">
          <Flex direction="column" w="50%" gap="13px">
            <Flex direction="column">
              <Text sx={WordOrderPanelSubtitle}>Work Order Type</Text>
              <Text>Property Settlement Reading</Text>
            </Flex>
            <Flex direction="column">
              <Text sx={WordOrderPanelSubtitle}>Meter Type</Text>
              <Text>ITRON</Text>
            </Flex>
            <Flex direction="column">
              <Text sx={WordOrderPanelSubtitle}>Meter Number</Text>
              <Text>BC16677430</Text>
            </Flex>
            <Flex direction="column">
              <Text sx={WordOrderPanelSubtitle}>Instruction</Text>
              <Text fontSize="14px">
                Lorem ipsum dolor sit amet, onsec tetur
                <br />
                adipiscing elit.
              </Text>
            </Flex>
            <Button sx={WorkOrderStartButton} onClick={handleStartButton}>
              {startButton}
            </Button>
          </Flex>
          <Flex direction="column" w="50%" gap="20px">
            <Box
              sx={{
                ...WorkOrderPanelStreetView,
                background: `url("${data.streetViewImage}") no-repeat`,
              }}
            >
              &nbsp;
            </Box>
            <Flex direction="row" justifyContent="space-between">
              <Flex direction="row" gap="20px">
                {data.hazards.map(
                  (
                    hazard: any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    index: number,
                  ) => (
                    <Flex direction="column" alignItems="center" key={index}>
                      <Icon as={hazard[0]} sx={WorkOrderPriorityFlagStyle} />
                      <Text sx={WorkOrderHazardTextStyle}>{hazard[1]}</Text>
                    </Flex>
                  ),
                )}
              </Flex>
              <Flex>
                <Button
                  sx={WorkOrderGoogleMapButtonStyle}
                  as={Link}
                  href={
                    'https://www.google.com/maps/search/?api=1&query=' +
                    encodeURI(
                      data.address + ',' + data.city + ',' + data.postcode,
                    )
                  }
                  isExternal
                >
                  Open on Google Map
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
