import { type SystemStyleObject } from '@chakra-ui/react';

const brandBlue = 'var(--chakra-colors-brandBlue)';

export const ItemFlexGlassStyle: SystemStyleObject = {
  padding: '25px 24px 10px 24px',
  borderRadius: '10px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  border: 'solid 1px rgba(255, 255, 255, 0.5)',
  backgroundColor: 'rgba(255, 255, 255, 0.45)',
};

export const ItemIconStyle: SystemStyleObject = {
  color: brandBlue,
  fontSize: '24px',
};

export const ItemTypeTextStyle: SystemStyleObject = {
  fontFamily: 'arialMed',
  color: brandBlue,
  fontWeight: 'bold',
};

export const ItemCountTextStyle: SystemStyleObject = {
  fontFamily: 'arialReg',
  fontSize: '32px',
  color: brandBlue,
};

export const ItemProgressBarFlexStyle: SystemStyleObject = {
  height: '6px',
  backgroundColor: '#fff',
  overflow: 'hidden',
  width: '100%',
  padding: '1px',
};

export const ItemProgressBarBoxStyle: SystemStyleObject = {
  height: '100%',
  backgroundColor: '#52c7e6',
};
