import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import {
  TakeMeterPictureModalCloseButton,
  TakeMeterPictureModalContentStyle,
  TakeMeterPictureModalGuide,
  TakeMeterPictureModalHeaderStyle,
  TakeMeterPictureModalHelptextStyle,
  TakeMeterPictureModalOverlay,
} from './TakeMeterPictureModal.styles.tsx';

type TakeMeterPictureModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TakeMeterPictureModal = ({
  isOpen,
  onClose,
}: TakeMeterPictureModalProps) => {
  const [cameraMode, setCameraMode] = useState<'user' | 'environment'>(
    'environment',
  );
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const startVideo = async (mode: 'user' | 'environment') => {
      // if (window.location.protocol !== 'https:') {
      //   console.warn('Camera access requires HTTPS');
      //   return;
      // }

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: mode },
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        } catch (error) {
          console.error('Error accessing the camera', error);
        }
      } else {
        console.warn('getUserMedia not supported');
      }
    };

    if (isOpen) {
      startVideo(cameraMode);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const video = videoRef.current;
      if (video && video.srcObject) {
        const tracks = (video.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
        video.srcObject = null;
      }
    };
  }, [isOpen, cameraMode]);

  const handleFlipCamera = () => {
    setCameraMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={TakeMeterPictureModalContentStyle}>
        <Flex direction="column">
          <Flex sx={TakeMeterPictureModalHeaderStyle}>
            <Button onClick={onClose} sx={TakeMeterPictureModalCloseButton}>
              Close
            </Button>
            <Text sx={TakeMeterPictureModalHelptextStyle}>
              Fit the meter reading closely inside the rectangle.
            </Text>
            <Button
              onClick={handleFlipCamera}
              sx={TakeMeterPictureModalCloseButton}
            >
              Flip Camera
            </Button>
          </Flex>
        </Flex>
        <video
          ref={videoRef}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <Box sx={TakeMeterPictureModalOverlay}>
          <Box sx={TakeMeterPictureModalGuide}>&nbsp;</Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};
