import streetViewImage1 from 'assets/streetview01.jpeg';
import streetViewImage2 from 'assets/streetview02.jpeg';
import streetViewImage3 from 'assets/streetview03.jpeg';
import streetViewImage4 from 'assets/streetview04.jpeg';
import streetViewImage5 from 'assets/streetview05.png';
import streetViewImage6 from 'assets/streetview06.jpeg';
import streetViewImage7 from 'assets/streetview07.jpeg';
import streetViewImage8 from 'assets/streetview08.jpeg';
import streetViewImage9 from 'assets/streetview09.png';

import {
  DogHazardIcon,
  ReadMeterCycleBillingIcon,
  ReadMeterPropertyIcon,
  SnakeHazardIcon,
} from '../assets/icons';

export const workOrders: WorkOrderType[] = [
  {
    id: 1,
    address: '68-58 Glyde Rd',
    city: 'Lesmurdie',
    postcode: 'WA 6076',
    distance: 56.4,
    hazards: [
      [DogHazardIcon, 'Dog'],
      [SnakeHazardIcon, 'Snake'],
    ],
    priority: true,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage1,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 2,
    address: '73-87 Cotherstone Rd',
    city: 'Kalamunda',
    postcode: 'WA 6076',
    distance: 49.7,
    hazards: [],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage2,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 3,
    address: 'Unit 6, 356-336 Sultana Rd E',
    city: 'Maida Vale',
    postcode: 'WA 6057',
    distance: 43.9,
    hazards: [],
    priority: true,
    type: ReadMeterCycleBillingIcon,
    streetViewImage: streetViewImage3,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 4,
    address: '53 Harrison Rd',
    city: 'Forrestfield',
    postcode: 'WA 6058',
    distance: 39.8,
    hazards: [[DogHazardIcon, 'Dog']],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage4,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 5,
    address: '29-55 Bruce Rd',
    city: 'Maida Vale',
    postcode: 'WA 6057',
    distance: 32.1,
    hazards: [[DogHazardIcon, 'Dog']],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage5,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 6,
    address: '58-40 Myerson Cres',
    city: 'Maida Vale',
    postcode: 'WA 6057',
    distance: 20.8,
    hazards: [[SnakeHazardIcon, 'Snake']],
    priority: false,
    type: ReadMeterCycleBillingIcon,
    streetViewImage: streetViewImage6,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 7,
    address: 'Unit 1103, 55-93 Bandalong Way',
    city: 'High Wycombe',
    postcode: 'WA 6057',
    distance: 17.9,
    hazards: [[DogHazardIcon, 'Dog']],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage7,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 8,
    address: '13 Rosebery Wy',
    city: 'Bushmead',
    postcode: 'WA 6055',
    distance: 13.5,
    hazards: [],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage8,
    previousReading: '01140427',
    status: 'pending',
  },
  {
    id: 9,
    address: '29-55 Bruce Rd',
    city: 'Maida Vale',
    postcode: 'WA 6057',
    distance: 8.1,
    hazards: [[DogHazardIcon, 'Dog']],
    priority: false,
    type: ReadMeterPropertyIcon,
    streetViewImage: streetViewImage9,
    previousReading: '01140427',
    status: 'pending',
  },
];

export type WorkOrderType = {
  id: number;
  address: string;
  city: string;
  postcode: string;
  distance: number;
  hazards: [any, string][]; // eslint-disable-line @typescript-eslint/no-explicit-any
  priority: boolean;
  type: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  streetViewImage: string;
  previousReading: string;
  status: 'pending' | 'completed' | 'interrupted';
};
