import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    // Please don't define sizes (height, width, min*, margin, padding, borderWidth, etc) in here -
    // it causes multipart components to break! (Eg mismatched sizes between Input / InputAddonLeft)
    // Instead, put such properties in the `componentTheme.sizes`.
  },
});

/**
 * Input BG colour must be set at the variant level, rather than baseStyle:
 * https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%402.4.2/packages/components/theme/src/components/input.ts#L115
 * https://stackoverflow.com/a/74885378/2586761
 */
const filledVariant = definePartsStyle((props) => ({
  // default values commented for context
  field: {
    color: 'bodyText',
    bg: mode('white', 'whiteAlpha.800')(props), // FIXME prefer semantic colour
    borderColor: 'border', // FIXME prefer semantic colour
    borderWidth: '1px', // FIXME prefer token

    _hover: {
      bg: mode('white', 'whiteAlpha.800')(props),
    },
    _readOnly: {
      // boxShadow: 'none !important',
      // userSelect: 'all',
    },
    _invalid: {
      borderColor: 'borderError',
    },
    _focusVisible: {
      borderColor: 'borderFocus',
      bg: mode('white', 'whiteAlpha.800')(props),
    },
  },
  addon: {},
}));

/**
 * `borderRadius` must be set for each size (baseStyle)
 * chakra-ui/packages/components/theme/src/components/input.ts:27
 * Discussion: https://github.com/chakra-ui/chakra-ui/discussions/4173
 */
const sizes = {
  //
};

/**
 * Input is a MultiPart Component
 *
 * @see https://chakra-ui.com/docs/components/input/theming
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts
 *
 * FIXME paddingX is missing from some Input components like:
 *
 * ```jsx
 * <InputGroup>
 *   <InputLeftAddon />
 *   <Input type="tel" />
 * </InputGroup>`
 * ```
 */
export const inputTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,

  sizes,

  variants: {
    filled: filledVariant,
  },

  defaultProps: {
    variant: 'filled',
    // size: 'sm',
  },
});
