import { type SystemStyleObject } from '@chakra-ui/react';

import googlemapImage from 'assets/googlemapsmodal.jpg';

export const GoogleMapModalContentStyle: SystemStyleObject = {
  width: '100%',
  maxWidth: '100vw',
  height: '100vh',
  margin: 0,
};

export const GoogleMapModalHeaderStyle: SystemStyleObject = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  backgroundColor: '#288fce',
  height: '64px',
  alignItems: 'center',
  paddingX: 4,
};

export const GoogleMapModalCloseButton: SystemStyleObject = {
  background: 'transparent',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'arialMed',
  fontSize: '16px',
  _hover: {
    backgroundColor: 'transparent',
  },
};

export const GoogleMapModalImage: SystemStyleObject = {
  backgroundImage: `url("${googlemapImage}")`,
  backgroundSize: 'cover',
  height: '100%',
};
