import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @import url("https://p.typekit.net/p.css?s=1&k=cyp8sfd&ht=tk&f=36604&a=208517134&app=typekit&e=css");
      /* Arial-Reg */
      @font-face {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./assets/fonts/Arial-Reg.TTF') format('ttf');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* Arial-Med */
      @font-face {
        font-family: 'Arial-Medium';
        font-style: medium;
        font-weight: 500;
        font-display: swap;
        src: url('./assets/fonts/Arial-Med.ttf') format('ttf');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* Arial-Bold */
      @font-face {
        font-family: 'Arial-Bold';
        font-style: bold;
        font-weight: 700;
        font-display: swap;
        src: url('./assets/fonts/Arial-Bold.TTF') format('ttf');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* URW DIN-Medium*/
      @font-face {
      font-family:"urw-din";
      src:url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/d96df7/00000000000000007735a843/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
      font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
      }
      `}
  />
);
