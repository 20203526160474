import { type SystemStyleObject } from '@chakra-ui/react';
import { type CSSProperties } from 'react';

const brandBlue = 'var(--chakra-colors-brandBlue)';

export const MyProfileMainFlexStyle: SystemStyleObject = {
  flexDirection: 'column',
  gap: '20px',
  paddingTop: '30px',
};

export const MyProfileHeadingStyle: SystemStyleObject = {
  fontFamily: 'arialMed',
  fontSize: '28px',
  color: brandBlue,
};

export const MyProfileIconStyle: SystemStyleObject = {
  color: brandBlue,
  fontSize: '40px',
  cursor: 'pointer',
};

export const MyProfileFlexGlassStyle: SystemStyleObject = {
  minHeight: '270px',
  padding: '24px',
  borderRadius: '10px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  border: 'solid 1px rgba(255, 255, 255, 0.5)',
  backgroundColor: 'rgba(255, 255, 255, 0.45)',
};

export const MyProfilePictureStyle: CSSProperties = {
  height: '160px',
  width: '160px',
};

export const MyProfileChangePictureButtonStyle: SystemStyleObject = {
  textTransform: '',
  fontFamily: 'arialBold',
  fontSize: '18px',
  color: 'white',
  backgroundColor: '#52c6e5',
  border: '2px solid white',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: '#288fce',
  },
};

export const ItemPictureStyle: CSSProperties = {
  width: '152px',
  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.23)',
};
