import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  type WorkOrderType,
  workOrders as workOrdersFixtures,
} from '../fixtures/WorkOrders.tsx';

function useProvideWorkOrders() {
  const [workOrders, _setWorkOrders] =
    useState<WorkOrderType[]>(workOrdersFixtures);

  const selectWorkOrderById = useCallback(
    (id?: number) => workOrders.find((workOrder) => workOrder.id === id),
    [workOrders],
  );

  const selectWorkOrdersByPending = (): WorkOrderType[] => {
    return workOrders.filter((workOrder) => workOrder.status === 'pending');
  };

  const selectWorkOrdersByCompleted = (): WorkOrderType[] => {
    return workOrders.filter(
      (workOrder) =>
        workOrder.status === 'completed' || workOrder.status === 'interrupted',
    );
  };

  const updateWorkOrder = (id: number, reading: string, status: string) => {
    const index = workOrders.findIndex((order) => order.id === id);

    if (index !== -1) {
      // Update the fields
      workOrders[index].previousReading = reading;
      workOrders[index].status = status as
        | 'pending'
        | 'completed'
        | 'interrupted';
    }
  };

  const workOrdersCount = useMemo(() => workOrders.length, [workOrders]);

  return {
    workOrders,
    workOrdersCount,
    selectWorkOrderById,
    selectWorkOrdersByPending,
    selectWorkOrdersByCompleted,
    updateWorkOrder,
  };
}

type WorkOrdersContext = ReturnType<typeof useProvideWorkOrders>;

const WorkOrdersContext = createContext(null as unknown as WorkOrdersContext);

type WorkOrdersProviderProps = {
  children: string | JSX.Element | JSX.Element[];
};

export function WorkOrdersProvider({ children }: WorkOrdersProviderProps) {
  const workorder = useProvideWorkOrders();
  return (
    <WorkOrdersContext.Provider value={workorder}>
      {children}
    </WorkOrdersContext.Provider>
  );
}

export const useWorkOrders = () => {
  const context = useContext(WorkOrdersContext);
  if (context === null) {
    throw new Error(
      'useWorkOrdersContext must be used within an WorkOrdersProvider',
    );
  }
  return context;
};
