import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import {
  ItemCountTextStyle,
  ItemFlexGlassStyle,
  ItemIconStyle,
  ItemProgressBarBoxStyle,
  ItemProgressBarFlexStyle,
  ItemTypeTextStyle,
} from './WorkOrderExperienceItem.styles.tsx';
import { ItemPictureStyle } from '../../screens/MyProfile/MyProfile.styles.tsx';

type WorkOrderExperienceItemProps = {
  icon: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  title: string;
  value: number;
  picture: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const WorkOrderExperienceItem = ({
  icon,
  title,
  value,
  picture,
}: WorkOrderExperienceItemProps) => {
  const percentage = (value / 500) * 100;

  return (
    <Flex direction="column" sx={ItemFlexGlassStyle} gap={2}>
      <Flex direction="row" justifyContent="space-between">
        {/* Icon and Work Order Type Text*/}
        <Flex direction="row" gap={4} alignItems="center">
          <Icon as={icon} sx={ItemIconStyle} />
          <Text sx={ItemTypeTextStyle}>{title}</Text>
        </Flex>
        {/* Count and Rating */}
        <Flex direction="row" gap="30px" alignItems="center">
          <Text sx={ItemCountTextStyle}>{value}</Text>
          <img style={ItemPictureStyle} src={picture} alt="Star Picture" />
        </Flex>
      </Flex>
      <Flex direction="row">
        <Flex sx={ItemProgressBarFlexStyle}>
          <Box sx={{ ...ItemProgressBarBoxStyle, width: `${percentage}%` }}>
            &nbsp;
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
