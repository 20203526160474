import { type SystemStyleObject } from '@chakra-ui/react';

import streetViewImage from 'assets/streetview01.jpeg';

const brandBlue = 'var(--chakra-colors-brandBlue)';

export const WorkOrderItemBox: SystemStyleObject = {
  margin: '18px 0 6px',
  padding: '16px 12px 13px 16px',
  borderRadius: '10px',
  backdropFilter: 'blur(21px)',
  boxShadow:
    '0 6px 11px 0 rgba(0, 51, 160, 0.2), inset 1px 1px 2px 0 #fff, inset 0 1px 37px 0 rgba(255, 255, 255, 0.71)',
  border: 'solid 1px rgba(255, 255, 255, 0.5)',
  backgroundColor: 'rgba(255, 255, 255, 0.45)',
};

export const WorkOrderFlexHeadingStyle: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '15px',
};

export const WorkOrderFlexLeftStyle: SystemStyleObject = {
  flexDirection: 'row',
  alignItems: 'center',
};
export const WorkOrderFlexRightStyle: SystemStyleObject = {
  flexDirection: 'row',
  gap: '18px',
  alignItems: 'center',
};

export const WorkOrderArrowsStyle: SystemStyleObject = {
  flexDirection: 'column',
  gap: '20px',
  color: brandBlue,
  cursor: 'grabbing',
  touchAction: 'none',
};

export const WorkOrderPriorityFlagStyle: SystemStyleObject = {
  width: '26px',
  height: '26px',
  color: brandBlue,
};

export const WorkOrderAddressStyle: SystemStyleObject = {
  color: brandBlue,
  userSelect: 'none',
  textAlign: 'left',
};

export const WorkOrderFlexDistanceStyle: SystemStyleObject = {
  color: brandBlue,
  userSelect: 'none',
};

export const WorkOrderPanelStyle: SystemStyleObject = {
  padding: '20px 0 0 0',
};

export const WordOrderPanelSubtitle: SystemStyleObject = {
  fontFamily: 'arialMed',
  fontSize: '12px',
  textTransform: 'Uppercase',
  color: brandBlue,
};

export const WorkOrderPanelStreetView: SystemStyleObject = {
  borderRadius: '10px',
  boxShadow: '0 2px 8px 0 rgba(24, 110, 157, 0.51)',
  height: '220px',
  background: `url("${streetViewImage}") no-repeat`,
  backgroundSize: 'cover',
};

export const WorkOrderHazardTextStyle: SystemStyleObject = {
  fontFamily: 'arialBold',
  fontSize: '12px',
  fontWeight: 'bold',
  color: brandBlue,
};

export const WorkOrderGoogleMapButtonStyle: SystemStyleObject = {
  textTransform: '',
  fontFamily: 'arialMed',
  fontSize: '14px',
  color: 'white',
  backgroundColor: '#52c6e5',
  border: '2px solid white',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: '#288fce',
  },
};

export const WorkOrderStartButton: SystemStyleObject = {
  textTransform: '',
  fontFamily: 'arialMed',
  fontSize: '14px',
  color: 'white',
  backgroundColor: '#0057ff',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: '#0057ff',
  },
  maxWidth: '300px',
};

export const WorkOrderStatusTextStyle: SystemStyleObject = {
  textTransform: 'capitalize',
  fontFamily: 'arialReg',
  fontWeight: 'bold',
};
