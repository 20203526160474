import { useEffect } from 'react';
import { Outlet, type useMatches } from 'react-router-dom';

import { applicationName } from 'constants/text';

import { useTitle } from './useTitle';

/** Add to these as required (after careful consideration) */
export type EventCategory = 'form-submit' | 'click';

/**
 * Custom events requiring 1-4 args
 *
 * > When creating Custom Events in your analytics it is important to consider the
 * > names you use carefully, as they will remain there forever. You should use
 * > descriptive names so it is easy to understand your reports, even when you can
 * > only see the Event Category or Event Action, for example.
 * >
 * > Letter case is important when defining Event Tracking values. Video, video,
 * > VIDEO, and ViDeO would all count as different events. Using consistent and
 * > descriptive names ensures that you will be able to understand and analyse the
 * > measured event data.
 *
 * - `Category` (Required) – This describes the type of events you want to track.
 *   For example, Link Clicks, Videos, Outbound Links, and Form Events.
 * - `Action` (Required) – This is the specific action that is taken. For example,
 *   with the Video category, you might have a Play, Pause and Complete action.
 * - `Name` (Optional – Recommended) – This is usually the title of the element
 *   that is being interacted with, to aid with analysis. For example, it could
 *   be the name of a Video that was played or the specific form that is being
 *   submitted.
 * - `Value` (Optional) – This is a numeric value and is often added dynamically.
 *   It could be the cost of a product that is added to a cart, or the
 *   completion percentage of a video.
 *
 * @example
 *
 * ```js
 * trackEvent(['Downloads', 'PDF Brochure Download', 'Matomo Event Tracking Brochure', 'blank'])
 * trackEvent(['Reviews', 'Published Matomo Review', 'blank', '10'])
 * ```
 *
 * https://developer.matomo.org/guides/tracking-javascript-guide#manually-trigger-events
 * https://matomo.org/guide/reports/event-tracking/#tracking-events
 * https://matomo.org/faq/reports/the-anatomy-of-an-event/
 */
type MatomoCustomEvent =
  | [command: 'trackEvent', category: EventCategory]
  | [command: 'trackEvent', category: EventCategory, name: string]
  | [
      command: 'trackEvent',
      category: EventCategory,
      action: string,
      name: string,
    ]
  | [
      command: 'trackEvent',
      category: EventCategory,
      action: string,
      name: string,
      value: number,
    ];

export type MotomoEvents =
  | ['trackPageView']
  | ['setDocumentTitle', string]
  | ['setCustomUrl', string]
  | ['trackAllContentImpressions']
  | ['trackVisibleContentImpressions', boolean, number]
  | ['setUserId', string]
  | ['resetUserId']
  | ['appendToTrackingUrl', 'new_visit=1' | '']
  | MatomoCustomEvent;

/**
 * Universal function for tracking events. All other tracking utils should use this function.
 */
export const trackEvent = (event: MotomoEvents) => {
  // console.log('⚪️ [useTrackPageView]', event);
  const _paq = (window._paq = window?._paq ?? []);
  _paq.push(event);
};

export const trackLogin = (userUuid: string) => {
  trackEvent(['setUserId', userUuid]);
};

/**
 * https://developer.matomo.org/guides/tracking-javascript-guide#when-user-logs-out-reset-user-id
 */
export const trackLogOut = () => {
  // User has just logged out, we reset the User ID
  trackEvent(['resetUserId']);

  // we also force a new visit to be created for the pageviews after logout
  trackEvent(['appendToTrackingUrl', 'new_visit=1']);

  trackEvent(['trackPageView']);

  // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
  trackEvent(['appendToTrackingUrl', '']);
};

export type routeWithHandle = Omit<
  ReturnType<typeof useMatches>[number],
  'handle'
> & {
  handle: { title: string };
};

/**
 * Update the document.title and Track changes on each navigation.
 */
export const useTrackPageView = () => {
  const title = useTitle();

  useEffect(() => {
    document.title = title ? `${title} | ${applicationName}` : applicationName;
    trackEvent(['setDocumentTitle', document.title]);

    // > By default, Matomo uses the URL of the current page as the Page URL in
    // > reports. You can customise the page URL to track by using the function
    // > `setCustomUrl`
    // https://developer.matomo.org/guides/tracking-javascript-guide#custom-page-url
    // https://matomo.org/faq/how-to/how-do-i-set-a-custom-url-using-the-matomo-javascript-tracker/
    // use window.location.href to account for the possible use of HashRouter
    trackEvent(['setCustomUrl', location.href]);

    // console.log('[trackPageView]', location.title, document.href);
    trackEvent(['trackPageView']);
  }, [title]);
};

export const TrackNavigation = () => {
  useTrackPageView();
  return <Outlet />;
};
