import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {},
  button: {
    justifyContent: 'space-between',
  },
  panel: {},
  icon: {},
  container: {
    // border: 0,
  },
});

const formCardSize = definePartsStyle({
  root: {},
  button: {
    justifyContent: 'space-between',
  },
  panel: {},
  icon: {},
});

/**
 * ```tsx
 * <Accordion allowToggle allowMultiple defaultIndex={[0]}>
 *   <AccordionItem>
 *     <AccordionButton>
 *       <Icon as={MdOutlineDescription} />
 *       <Heading size="xs">Heading</Heading>
 *       <AccordionIcon />
 *     </AccordionButton>
 *
 *     <AccordionPanel>Lorem ipsum dolor sit amet</AccordionPanel>
 *   </AccordionItem>
 * </Accordion>
 * ```
 */
const cube = definePartsStyle({
  root: {
    bgColor: 'white',
    // AccordionItems have top & bottom borders, no need to double up
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    paddingY: 4,
    color: 'primary',

    // we anticipate an icon to the left of a Heading
    'svg:first-of-type:not(.chakra-accordion__icon)': { marginRight: 2 },

    _hover: {
      bgColor: 'transparent',
    },
  },
  icon: {
    marginLeft: 'auto',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { formCard: formCardSize },
  variants: { cube },
  defaultProps: {
    variant: 'cube',
  },
});
