import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { Header } from 'layouts/Header';

import {
  MainLayoutBoxStyle,
  MainLayoutFlexStyle,
  MainLayoutInnerFlexStyle,
} from './MainLayout.styles.tsx';
import { WorkOrdersProvider } from '../../api/useWorkOrder.tsx';

export const MainLayout = () => {
  return (
    <Flex sx={MainLayoutFlexStyle}>
      <Flex sx={MainLayoutInnerFlexStyle}>
        <Header />
        <Box sx={MainLayoutBoxStyle}>
          <WorkOrdersProvider>
            <Outlet />
          </WorkOrdersProvider>
        </Box>
      </Flex>
    </Flex>
  );
};
