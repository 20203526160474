export type WorkOrderCommentType = {
  id: number;
  comment: string;
  author: string;
};

export const WorkOrderComments: WorkOrderCommentType[] = [
  {
    id: 1,
    comment: 'This is an example comment',
    author: 'Anthony Higginbottom',
  },
  {
    id: 2,
    comment: 'This is a second example comment',
    author: 'Sarah Malcom',
  },
];
