import { type SystemStyleObject } from '@chakra-ui/react';

import backgroundImage from 'assets/app-bg.png';

export const MainLayoutFlexStyle: SystemStyleObject = {
  flexDirection: 'row',
  // background: `
  //   linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(87, 164, 213, 0.3), rgba(167, 197, 233, 0.3)),
  //   linear-gradient(45deg, rgba(255, 255, 255, 0.3), rgba(127, 202, 224, 0.3), rgba(104, 178, 220, 0.3))
  // `,
  background: `url("${backgroundImage}") no-repeat`,
  backgroundBlendMode: 'overlay',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100vh',
  justifyContent: 'center',
  paddingTop: '25px',
  paddingBottom: '25px',
  overflow: 'hidden',
};

export const MainLayoutInnerFlexStyle: SystemStyleObject = {
  flexDirection: 'column',
  width: '728px',
  gap: '12px',
};

export const MainLayoutBoxStyle: SystemStyleObject = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    background: 'transparent',
  },
};
