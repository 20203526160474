import { Flex, Icon, Text } from '@chakra-ui/react';
import { useState } from 'react';

import {
  WorkOrderIconsFlexStyle,
  WorkOrderIconsTextStyle,
} from './WorkOrderIcons.styles.tsx';
import {
  LocateReadMeterIcon,
  PriorityFlagIcon,
  ReadMeterCycleBillingIcon,
  ReadMeterIcon,
  ReadMeterPropertyIcon,
  ReadMeterUsageIcon,
  VerifyMeterCycleBillingIcon,
} from '../../assets/icons';

const icons = [
  { id: 1, icon: PriorityFlagIcon, text: 'Priority Work Order' },
  { id: 2, icon: ReadMeterIcon, text: 'Usage Check' },
  { id: 3, icon: ReadMeterPropertyIcon, text: 'Read Meter' },
  { id: 4, icon: ReadMeterCycleBillingIcon, text: 'Property Settlement' },
  { id: 5, icon: VerifyMeterCycleBillingIcon, text: 'Cycle Billing' },
  { id: 6, icon: LocateReadMeterIcon, text: 'Verify Cycle Billing' },
  { id: 7, icon: ReadMeterUsageIcon, text: 'Locate and Read Meter' },
];

export const WorkOrderIcons = () => {
  const [expandedIcon, setExpandedIcon] = useState<null | number>(null);

  const handleClick = (id: number) => {
    setExpandedIcon(expandedIcon === id ? null : id);
  };

  return (
    <Flex sx={WorkOrderIconsFlexStyle}>
      {icons.map((item) => (
        <div
          key={item.id}
          className={`icon-container ${expandedIcon === item.id ? 'expanded' : ''}`}
          onClick={() => handleClick(item.id)}
        >
          <Icon as={item.icon} className="workordericon" />
          {expandedIcon === item.id && (
            <Text sx={WorkOrderIconsTextStyle}>{item.text}</Text>
          )}
        </div>
      ))}
    </Flex>
  );
};
