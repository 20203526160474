import { type SystemStyleObject } from '@chakra-ui/react';

import digitalIdPicture from 'assets/DigitalID.png';

export const DigitalIdModalContentStyle: SystemStyleObject = {
  width: '100%',
  maxWidth: '100vw',
  height: '100vh',
  margin: 0,
  background: 'transparent',
  padding: '20px',
};

export const DigitalIdModalOverlayStyle: SystemStyleObject = {
  backdropFilter: 'blur(8px)',
};

export const DigitalIdModalCloseButtonStyle: SystemStyleObject = {
  background: 'transparent',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'arialMed',
  fontSize: '16px',
  _hover: {
    backgroundColor: 'transparent',
  },
};

export const DigitalIdModalBoxStyle: SystemStyleObject = {
  width: '385px',
  height: '565px',
  backgroundImage: `url("${digitalIdPicture}")`,
  backgroundSize: 'cover',
};
