import { Button, Flex, Heading, Icon, Select, Text } from '@chakra-ui/react';
import {
  MdAccessTime,
  MdBuildCircle,
  MdCheckCircleOutline,
  MdChecklist,
  MdChevronLeft,
  MdHome,
  MdPlace,
} from 'react-icons/md';
import { useNavigate } from 'react-router';

import bronze2star from 'assets/b2s.png';
import gold1star from 'assets/g1s.png';
import gold2star from 'assets/g2s.png';
import profilePicture from 'assets/profilepic.png';
import silver3star from 'assets/s3s.png';
import { WorkOrderExperienceItem } from 'components/WorkOrderExperienceItem';

import {
  MyProfileChangePictureButtonStyle,
  MyProfileFlexGlassStyle,
  MyProfileHeadingStyle,
  MyProfileIconStyle,
  MyProfileMainFlexStyle,
  MyProfilePictureStyle,
} from './MyProfile.styles.tsx';

export const MyProfile = () => {
  const navigate = useNavigate();
  return (
    <Flex sx={MyProfileMainFlexStyle}>
      {/* Page Heading */}
      <Flex direction="row" gap="20px" alignItems="center">
        <Icon
          as={MdChevronLeft}
          sx={MyProfileIconStyle}
          onClick={() => navigate(-1)}
        />
        <Heading sx={MyProfileHeadingStyle}>My profile</Heading>
      </Flex>

      {/* Profile Information */}
      <Flex
        direction="row"
        sx={MyProfileFlexGlassStyle}
        justifyContent="space-between"
      >
        <Flex w="70%" direction="column" justifyContent="space-between">
          <Flex direction="column" gap="10px">
            <Heading sx={MyProfileHeadingStyle}>Anthony Higginsbottom</Heading>
            <Text>
              Seniority Level 3<br />
              ID #1224567
            </Text>
          </Flex>
          <Flex direction="column" gap="5px">
            <Text>Starting location:</Text>
            <Select w="390px">
              <option value="option1">Water Corporation Depot 1</option>
              <option value="option2">240 Balcatta Rd, Balcatta WA 6021</option>
              <option value="option3">My home</option>
            </Select>
          </Flex>
        </Flex>
        <Flex
          w="30%"
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          gap={3}
        >
          <img
            alt="Profile Picture"
            src={profilePicture}
            style={MyProfilePictureStyle}
          />
          <Button sx={MyProfileChangePictureButtonStyle}>Change picture</Button>
        </Flex>
      </Flex>

      {/* Work Order Experience */}
      <Flex direction="column" sx={MyProfileFlexGlassStyle} gap={2}>
        <Flex justifyContent="space-between">
          <Heading sx={MyProfileHeadingStyle}>Work Order Experience</Heading>
          <Select w="170px">
            <option value="option1">Year to date</option>
          </Select>
        </Flex>

        <WorkOrderExperienceItem
          icon={MdCheckCircleOutline}
          title="Read meter usage check"
          value={475}
          picture={gold1star}
        />

        <WorkOrderExperienceItem
          icon={MdChecklist}
          title="Read meter"
          value={206}
          picture={silver3star}
        />

        <WorkOrderExperienceItem
          icon={MdHome}
          title="Property Settlement Reading"
          value={499}
          picture={gold2star}
        />

        <WorkOrderExperienceItem
          icon={MdAccessTime}
          title="Read meter cycle billing"
          value={119}
          picture={bronze2star}
        />

        <WorkOrderExperienceItem
          icon={MdBuildCircle}
          title="Verify reading cycle billing"
          value={320}
          picture={gold1star}
        />

        <WorkOrderExperienceItem
          icon={MdPlace}
          title="Locate and read meter"
          value={87}
          picture={bronze2star}
        />
      </Flex>
    </Flex>
  );
};
