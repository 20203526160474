import { type SystemStyleObject } from '@chakra-ui/react';

export const WorkOrderIconsFlexStyle: SystemStyleObject = {
  flexDirection: 'row',
  '> div.icon-container': {
    cursor: 'pointer',
    padding: '10px',
    transition: 'all 0.3s linear',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '16px',
  },
  '.workordericon': {
    color: 'var(--chakra-colors-brandBlue)',
    fontSize: '24px',
  },
  '> div.icon-container.expanded': {
    backgroundColor: 'var(--chakra-colors-brandBlue)',
    borderRadius: '16px',
    '> .workordericon': {
      color: 'white',
    },
  },
};

export const WorkOrderIconsTextStyle: SystemStyleObject = {
  marginLeft: '10px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  color: 'white',
  fontFamily: 'arialMed',
};
