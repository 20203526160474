import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  WorkOrderMeterReadingHeaderStyle,
  WorkOrderMeterReadingModalBodyStyle,
  WorkOrderMeterReadingModalFooterStyle,
  WorkOrderMeterReadingOverlayStyle,
  WorkOrderMeterReadingPinContainerStyle,
} from './WorkOrderMeterReadingModal.styles.tsx';

type WorkOrderMeterReadingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  saveReading: (reading: string) => void;
};

export const WorkOrderMeterReadingModal = ({
  isOpen,
  onClose,
  saveReading,
}: WorkOrderMeterReadingModalProps) => {
  const [readingValue, setReadingValue] = useState<string>('');
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay sx={WorkOrderMeterReadingOverlayStyle} />
      <ModalContent>
        <ModalHeader sx={WorkOrderMeterReadingHeaderStyle}>
          Manual meter reading
        </ModalHeader>
        <ModalBody sx={WorkOrderMeterReadingModalBodyStyle}>
          <Flex
            direction="row"
            justifyContent="center"
            gap={2}
            sx={WorkOrderMeterReadingPinContainerStyle}
          >
            <PinInput placeholder="" onComplete={(v) => setReadingValue(v)}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </Flex>
        </ModalBody>

        <ModalFooter sx={WorkOrderMeterReadingModalFooterStyle}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => saveReading(readingValue)}
            isDisabled={readingValue === ''}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
