import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';

import { type routeWithHandle } from './useTracking';

/**
 * @returns the title for the current route defined as route handle.title
 *
 * ```
 * const routes = [
 *   {
 *     path: '/',
 *     element: <div />,
 *     handle: { title: 'My Title' }
 *   }
 * ]
 * ```
 */
export const useTitle = () => {
  const matches = useMatches();
  const title = useMemo(
    () =>
      matches
        .filter((match) => Boolean((match as routeWithHandle).handle?.title))
        .map((match) => (match as routeWithHandle).handle?.title)?.[0] ?? '',
    [matches],
  );
  return title;
};
