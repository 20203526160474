import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

/**
 * Checkbox is a MultiPart Component
 *
 * @see https://chakra-ui.com/docs/components/checkbox/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/checkbox.ts
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/checkbox/src/checkbox.tsx
 */
export const checkboxTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    /** label */
    container: {},
    /** span wraps the icon */
    control: {
      borderColor: 'gray.400', // FIXME semantic colours\
    },
    /**  */
    icon: {},
    /** children */
    label: {
      color: '#383838', // FIXME semantic colours
    },
  },

  /** setting size on Checkbox controls fontSize of the label */
  sizes: {
    sm: {
      control: {
        borderWidth: 1,
      },
      label: {
        fontSize: '12px', // prefer tokens
      },
    },
    md: {
      control: {
        borderWidth: 2,
      },
      label: {
        fontSize: '14px', // prefer tokens
      },
    },
    // lg: {},
  },

  variants: {
    // so we can deselect 'inverted' in storybook
    default: {},

    // TODO: this is the bare minimum for demo purposes
    mono: {
      control: {
        bg: 'bodyBg',
        color: 'bodyText',
        borderColor: 'bodyText',

        _checked: {
          bg: 'bodyBg',
          color: 'bodyText',
          borderColor: 'bodyText',
        },
      },
      icon: {
        stroke: 'bodyText',
      },
      label: {
        color: 'bodyText',
      },
    },

    monoInverted: {
      control: {
        bg: 'bodyBgInverted',
        color: 'bodyTextInverted',
        borderColor: 'bodyBgInverted',

        _checked: {
          bg: 'bodyBgInverted',
          color: 'bodyTextInverted',
          borderColor: 'bodyBgInverted',
        },
      },
      icon: {
        stroke: 'bodyText',
      },
      label: {
        color: 'bodyText',
      },
    },

    fixme: (props) => {
      // FIXME always returns false
      const isLight = props.colorMode === 'light';
      return {
        control: {
          bg: isLight ? 'red' : 'blue', // fixme not working!
          // bg: mode('red', 'blue')(props), // also fails!
        },
      };
    },
  },

  // defaultProps: {},
});
